import { atom } from 'jotai'

import { GetMeResponse } from '@/typings/api'

export const meAtom = atom<GetMeResponse | undefined>(undefined)

export const setMeAtom = atom(null, (get, set, info: GetMeResponse) => set(meAtom, info))

export const clearMeAtom = atom(null, (_get, set) => set(meAtom, undefined))

export const orgAtom = atom<{
  orgId: string
  orgName: string
  occupiedSeatCount: number
  purchasedSeatsCount: number
}>({ orgId: '', orgName: '', occupiedSeatCount: 0, purchasedSeatsCount: 0 })
